import { navigate } from 'gatsby-plugin-intl';
import { useEffect } from 'react';

function Smc() {
  useEffect(() => {
    navigate('/mclan?utm_source=smc', { replace: true });
  });

  return null;
}

export default Smc;
